.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  gap: 10px;
  flex-direction: column;
}

.link {
  color: var(--color-brand-primaryBlue);
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text-primary);
}
